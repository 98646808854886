import React, { useState, useMemo, useEffect } from 'react';
import { Polyline } from 'react-leaflet';
import ArrayService from '../../services/ArrayService';
import { HOME_LATLNG } from './HomeMarker';

interface Props {
    deliveries?: Delivery[];
    deliveryZones?: DeliveryZone[];
    clients: Client[];
    deliveryZoneId: Id | null;
}
interface Delivery {
    id: string;
    color: string;
    deliveryZone: {
        id: Id;
    }
    deliveryCommands: {
        sortOrder: number;
        command: {
            id: string;
            client: {
                id: number;
            };
        };
    }[];
}
interface DeliveryZone {
    id: string;
    color: string;
    defaultClients?: {
        id: Id;
        client: {
            id: number;
        };
    }[];
}
interface Client {
    id: number;
    address: {
        coordinates: {
            lat: number;
            lng: number;
        };
    };
}
interface Address {
    coordinates: {
        lat: number;
        lng: number;
    };
}

interface SanitazedZone {
    id: Id;
    color: string;
    clients: {
        id: number;
    }[];
}

export default function DeliveryPath({
    deliveries,
    deliveryZones,
    deliveryZoneId,
    clients,
}: Props) {
    const roundPaths = useMemo(() => {
        // We convert the given zones versions to a standard common in the rest of the component.
        let sanitazedZones: SanitazedZone[] = [];
        if (deliveries) {
            sanitazedZones = deliveries
                .filter((delivery) =>
                    deliveryZoneId != null
                        ? delivery.deliveryZone.id === deliveryZoneId
                        : true
                )
                .map((delivery) => ({
                id: delivery.id,
                color: delivery.color,
                clients: delivery.deliveryCommands
                    .sort(ArrayService.cmpBy('sortOrder'))
                    .map((c) => c.command.client),
            }));
        } else if (deliveryZones) {
            sanitazedZones = deliveryZones
                .filter((zone) =>
                    deliveryZoneId != null
                        ? zone.id === deliveryZoneId
                        : true
                )
                .map((zone) => ({
                id: zone.id,
                color: zone.color,
                clients: zone.defaultClients
                    ? zone.defaultClients
                          .sort(ArrayService.cmpBy('sortOrder'))
                          .map((c) => c.client)
                    : [],
            }));
        }
        return sanitazedZones.map((delivery) => ({
            id: delivery.id,
            color: delivery.color,
            path: getRoundPath(delivery, clients),
        }));
    }, [deliveries, deliveryZones, clients]);
    return (
        <>
            {roundPaths.map((r) => (
                <Polyline
                    key={r.id}
                    positions={r.path}
                    color="#ffffff"
                    opacity={0.5}
                    weight={10}
                />
            ))}
            {roundPaths.map((r) => (
                <AnnimatedPolyline
                    key={r.id}
                    positions={r.path}
                    color={r.color}
                />
            ))}
        </>
    );
}

interface AnnimatedPolylineProps {
    positions: [number, number][];
    color: string;
}
function AnnimatedPolyline({ positions, color }: AnnimatedPolylineProps) {
    const [dashOffset, setDashOffset] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDashOffset((prev) => (prev + 1) % 12);
        }, 80);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <Polyline
            positions={positions}
            color={color}
            weight={1}
            dashArray="4, 8"
            dashOffset={(dashOffset * -1).toString()}
        />
    );
}

function getRoundPath(
    round: SanitazedZone,
    clients: Client[],
): [number, number][] {
    const positions = round.clients
        .map((c) => {
            const client = clients.find((cl) => cl.id === c.id);
            return client && [getLat(client.address), getLng(client.address)];
        })
        .filter((c) => !!c) as [number, number][];

    return [
        [HOME_LATLNG.lat, HOME_LATLNG.lng],
        ...positions,
        [HOME_LATLNG.lat, HOME_LATLNG.lng],
    ];
}

function getLat(address: Address) {
    return address.coordinates && address.coordinates.lat
        ? address.coordinates.lat
        : HOME_LATLNG.lat;
}
function getLng(address: Address) {
    return address.coordinates && address.coordinates.lng
        ? address.coordinates.lng
        : HOME_LATLNG.lng;
}
