import React, { ReactElement, Suspense } from 'react';
import { Layout, Menu, Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

import './Layout.scss';
import logoIcon from 'shared/images/logo_white.svg';
import logoText from 'shared/images/text_white.svg';
import { AuthConsumer } from 'shared/contexts/AuthContext';
import LoaderOverlay from '../LoaderOverlay';

interface MenuItem {
    route: string;
    icon: ReactElement;
    label: string;
}

interface LayoutProps {
    menuItems?: MenuItem[];
    selectedMenu?: string | null;
    onMenuClick?: (route: string) => void;
    children: any;
    logout?: boolean;
}


export default function MobileLayout({
    menuItems,
    selectedMenu,
    onMenuClick,
    children,
    logout,
}: LayoutProps) {
    return (
        <Layout style={{ minHeight: '100vh' }} className="mobile-layout">
            <div className="__header">
                <div className="__logo">
                    <img className="__icon" alt="Accueil" src={logoIcon} />
                    <img className="__text" alt="Accueil" src={logoText} />
                </div>
                {logout && (
                    <AuthConsumer>
                        {({ updateAuthToken }) => (
                            <Button
                                className="__logout-btn"
                                onClick={() => updateAuthToken(null)}
                                icon={<LogoutOutlined />}
                                shape="circle"
                                size="large"
                            />
                        )}
                    </AuthConsumer>
                )}
            </div>
            <Layout.Content>
                <Suspense
                    fallback={<LoaderOverlay size="large" loading={true} />}
                >
                    {children}
                </Suspense>
            </Layout.Content>
            <Menu
                className="__menu"
                onClick={(e) => onMenuClick && onMenuClick(e.key)}
                selectedKeys={selectedMenu ? [selectedMenu] : []}
                mode="inline"
            >
                {menuItems &&
                    menuItems.map((item) => (
                        <Menu.Item key={item.route}>
                            {item.icon}
                            <div className="__label">{item.label}</div>
                        </Menu.Item>
                    ))}
            </Menu>
        </Layout>
    );
}
